<template>
  <section class="category-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary" ></b-overlay>
    <validation-observer ref="observer" class="w-100">
      <b-row>
        <b-col :md="12">
          <b-form-group :label="$t('message.Pinfl')">
            <b-input v-model="form.pinfl"></b-input>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <div class="w-100">
            <b-form-group :label="$t('message.Firstname')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.Firstname')"
                  rules="required"
              >
                <b-input v-model="form.first_name" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
        <b-col :md="6">
          <div class="w-100">
            <b-form-group :label="$t('message.Lastname')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.Lastname')"
                  rules="required"
              >
                <b-input v-model="form.last_name" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
        <b-col :md="6">
          <div class="w-100">
            <b-form-group :label="$t('message.Middlename')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.Middlename')"
                  rules="required"
              >
                <b-input v-model="form.middle_name" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
        <b-col :md="6">
          <div class="w-100">
            <b-form-group :label="$t('message.Email')">
              <validation-provider
                  #default="{ errors }"
                  :name="$t('message.Email')"
                  rules="required"
              >
                <b-input v-model="form.email" required></b-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.SchoolType')">
            <validation-provider
                #default="{ errors }"
                :name="$t('message.SchoolType')"
                rules="required"
            >
              <b-form-select :options="schoolTypes" text-field="name" value-field="id" v-model="form.school_type"></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.BirthDate')">
            <validation-provider
                #default="{ errors }"
                :name="$t('message.BirthDate')"
                rules="required"
            >
              <input type="date" class="form-control mb-2" v-model="form.birth_date">
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.PhoneNumber')">
            <validation-provider
                #default="{ errors }"
                :name="$t('message.PhoneNumber')"
                rules="required"
            >
              <b-input type="text" name="phone_number" v-model="form.phone_number" v-mask="'+998 ## ### ## ##'"></b-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.SecondNumber')">
              <b-input type="text" name="second_number" v-model="form.second_number_second" v-mask="'+998 ## ### ## ##'"></b-input>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.Region')">
            <validation-provider
                #default="{ errors }"
                :name="$t('message.Region')"
                rules="required"
            >
              <b-form-select :options="regions" text-field="nameuz" value-field="id" v-model="form.region" @input="fetchRayons"></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.Rayon')">
            <validation-provider
                #default="{ errors }"
                :name="$t('message.Rayon')"
                rules="required"
            >
              <b-form-select :options="rayons" text-field="region" value-field="id" v-model="form.rayon"></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.Street')">
            <validation-provider
                #default="{ errors }"
                :name="$t('message.Street')"
                rules="required"
            >
              <b-input v-model="form.street" name="street" type="text" ></b-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.SchoolNumber')">
            <validation-provider
                #default="{ errors }"
                :name="$t('message.SchoolNumber')"
                rules="required"
            >
              <b-input v-model="form.school_number" name="street" type="text" ></b-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.SchoolPhoneNumber')">
            <validation-provider
                #default="{ errors }"
                :name="$t('message.SchoolPhoneNumber')"
                rules="required"
            >
              <b-input type="text" name="phone_number" v-model="form.school_phone_number" v-mask="'+998 ## ### ## ##'"></b-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.Experience')">
          <validation-provider
              #default="{ errors }"
              :name="$t('message.Experience')"
              rules="required"
          >
            <b-form-select :options="experiences" text-field="name_ru" value-field="id" v-model="form.experience"></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.file')">
              <b-form-file
                  v-model="form.file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
              ></b-form-file>
          </b-form-group>
        </b-col>
        <b-col :md="6">
          <b-form-group :label="$t('message.photo')">
<!--              <input type="file" @change="getPhoto">-->
              <b-form-file
                  v-model="form.photo"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
              ></b-form-file>
          </b-form-group>
        </b-col>
        <b-col :md="6">
        </b-col>
        <b-col :md="6">
          <div v-if="!id" class="w-100">
            <b-form-group :label="$t('message.Password')">
                <b-input type="password" v-model="form.password" required></b-input>
            </b-form-group>
          </div>
          <div v-else class="w-100">
            <b-form-group :label="$t('message.Password')">
              <b-input type="password" v-model="form.password" required></b-input>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-divider></b-divider>
      </validation-observer>
    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="success" @click="saveSubmit" >{{$t('message.Add')}}</b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { storeTeacher, updateTeacher, showUser, getSchoolTypes } from '@/api/users'
import {getExperiences} from '@/api/experience'
import {getRoles} from '@/api/users'
import { formTemplate } from '@/utils/mixins/formTemplate';
import { quillEditor }  from 'vue-quill-editor';
import vSelect from 'vue-select';
import { VueMaskDirective } from 'v-mask'
import axios from 'axios'
import { getAllRegions ,getRegionRayons} from '@/api/region'

function initForm(data) {
  const get = (key, value = null) => _.get(data,key,value);

  return {
    id: get('id', null),
    first_name: get('first_name', null),
    last_name: get('last_name', null),
    middle_name: get('middle_name', null),
    email: get('email', null),
    password: get('password', null),
    role_id: get('role_id', null),
    school_type: get('school_type', null),
    birth_date: get('birth_date', null),
    phone_number: get('phone_number', null),
    second_number_second: get('second_number_second', null),
    region: get('region', null),
    rayon: get('rayon', null),
    street: get('street',null),
    school_number: get('school_number', null),
    school_phone_number: get('school_phone_number', null),
    experience: get('experience', null),
    file: get('file', null),
    photo: get('photo',null),
    pinfl: get('pinfl',null),
  }
}

const actions = {
  add:storeTeacher,
  update: updateTeacher,
  show: showUser,
}

export default {
  name: 'TeacherForm',
  directives: {
    mask: VueMaskDirective,
  },
  props: {
    id: {
      required: false,
    },
    regions: {
      type: Array,
    },
  },
  components:{
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [
    formTemplate,
  ],
  data() {
    return {
      actions,
      required,
      snowOption: {
        theme: 'snow',
      },
      form: initForm(),
      allCategories: [],
      allTypes: [],
      roles: [],
      schoolTypes: [],
      experiences: [],
      rayons: [],
      filters: {
        has_profile: true,
      }
    }
  },
  created() {
    this.fetchRoles();
  },
  mounted() {
    getSchoolTypes().then(res => {
      this.schoolTypes = res.data.data
    })
    getExperiences().then(res => {
      this.experiences = res.data.data;
    });

    },
  methods: {
    checkFile() {
      console.log(this.form.file)
    },
    reformatData() {
      this.form = {...this.form, nomination_id: this.$route.params.id};
      let form = this.prepareFormData(this.form);
      return form;
    },
    setForm(data) {
      this.form = initForm(data);
    },
    fetchRoles() {
      getRoles().then(res => {
        this.roles = res.data.data;
      })
    },
    fetchRayons() {
      getRegionRayons({region_id: this.form.region}).then(res => {
        this.rayons = res.data.data;
      })
    },
    getWorkFile(e) {
      // eslint-disable-next-line prefer-destructuring
      this.form.file = e.target.files[0]
    },
    getPhoto(e) {
      // eslint-disable-next-line prefer-destructuring
      this.form.photo = e.target.files[0]
    },
  },
}
</script>

<style scoped>

</style>
