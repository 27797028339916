<template>
  <section class="users">
    <div class="w-100 mt-3">
      <custom-filter @handleSearch="fetchList" @handleAdd="handleAdd">
        <template slot="customFilters">
          <b-col cols="2">
            <b-form-group>
              <b-form-select :options="school_types" text-field="name" value-field="id" v-model="filters.school_type_id"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col :cols="2">
            <b-form-group label="">
                <b-form-select
                    :options="allRegions"
                    text-field="nameuz"
                    value-field="id"
                    v-model="filters.region"
                    @input="fetchRayons"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col :cols="2">
            <b-form-group label="">
                <b-form-select
                    :options="rayons"
                    text-field="region"
                    value-field="id"
                    v-model="filters.rayon"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-button variant="warning" @click="getClear">
              <b-icon icon="arrow-repeat"></b-icon>
            </b-button>
            <b-button variant="info" class="ml-2" @click="getSearch">
              <b-icon icon="search"></b-icon>
              {{$t('message.Search')}}
            </b-button>
          </b-col>
        </template>
      </custom-filter>
      <div class="w-100 mb-1">
        <h6>{{$t('message.TotalCount')}} : {{total}}</h6>

      </div>
      <b-card>
        <b-table :items="datas" :fields="fields" responsive>
          <template #cell(avatar)="{item}">
            <b-avatar variant="info" :src="$backendHost+item.avatar"></b-avatar>
          </template>
          <template #cell(file)="{item}">
            <div v-if="item.files.length">
              <a v-for="file in item.files" :key="file.id"
                 :href="$backendHost+file.file" target="_blank" class="btn btn-outline-primary btn-sm">
                <b-icon icon="download"></b-icon>
              </a>
            </div>
          </template>
          <template #cell(created_at)="{item}">
            {{item.created_at | dateBueutify}}
          </template>
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button variant="primary" size="sm" @click="handleEdit(data.item.id)" >
                <b-icon icon="pencil"></b-icon>
              </b-button>
              <b-button class="ml-1" variant="danger" size="sm" @click="handleDelete(data.item.id)" >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-end mt-3">
          <b-pagination
              v-model="pagination.page"
              hide-goto-end-buttons
              :total-rows="total"
              :per-page="pagination.pageSize"
              @input="fetchList()"
          />
        </div>
      </b-card>
    </div>
    <b-modal id="modal-lg" size="lg" hide-footer :title="modalTitle" v-model="modalVisible" >
      <TeacherForm :id="id" :regions="regions" @saved="fetchList" />
    </b-modal>
  </section>
</template>

<script>
import { getTeachers, deleteUser, getRoles } from '@/api/users'
import { listTemplate } from '@/utils/mixins/listTemplate'
import CustomFilter from '@/views/components/CustomFilter'
import TeacherForm from '@/views/users/TeacherForm'
import moment from 'moment'
import { getAllRegions, getRegionRayons } from '@/api/region'

const actions = {
  get: getTeachers,
  delete: deleteUser,
}


export default {
  name: 'Teachers',
  components:{
    CustomFilter,
    TeacherForm,
    moment,
  },
  mixins:[
    listTemplate,
  ],
  created() {
    this.fetchRoles();
    this.fetchRegions()
  },
  filters: {
    dateBueutify(val) {
      return moment(val).format('DD.MM.YYYY');
    },
  },
  data() {
    return {
      actions,
      roles: [],
      school_types: [],
      regions: [],
      fields: [
        {key:'id', label: this.$t('ID')},
        {key:'avatar', label: this.$t('Avatar')},
        {key:'first_name', label: this.$t('FirstName')},
        {key:'last_name', label: this.$t('LastName')},
        {key:'middle_name', label: this.$t('MiddleName')},
        {key:'email', label: this.$t('Email')},
        {key: 'role.display_name', label: this.$t('message.Role')},
        {key: 'profile.user_school_type.name', label: this.$t('message.SchoolType')},
        {key: 'profile.phone_number', label: this.$t('message.PhoneNumber')},
        {key: 'created_at', label: this.$t('message.Date')},
        {key:'file',label: this.$t('message.File')},
        {key:'action', label: this.$t('Action')},
      ],
      filters: {
        nomination_id: this.$route.params.id,
        role_id: null,
        school_type_id: null,
        region: null,
        rayon: null,
        search: ''
      },
      rayons: [],
      allRegions: []
    }
  },
  methods: {
    fetchRoles() {
      getRoles().then(res => {
        this.roles =  res.data.data;
        this.roles.unshift({id:null, display_name:'Role'})
        this.school_types = res.data.school_types;
        this.school_types.unshift({id: null, name: this.$t('message.SchoolType')})
      })
    },
    fetchRegions() {
      getAllRegions().then(res => {
        this.regions = res.data.data.result;
        this.allRegions = res.data.data.result
        this.allRegions.unshift({nameuz: this.$t('message.Region'), id: null, })
      })
    },
    fetchRayons() {
      getRegionRayons({region_id: this.filters.region }).then(res => {
        this.rayons = res.data.data;
        this.rayons.unshift({ region: this.$t('message.Rayon'), id: null })
      })
    },
    getSearch() {
      this.pagination.page = 1;
      this.fetchList()
    },
    getClear() {
      this.pagination.page = 1;
      this.filters.role_id = null;
      this.filters.search = null;
      this.filters.rayon = null;
      this.filters.region = null
      this.fetchList()
    }
  }
}
</script>

<style scoped>

</style>
