var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"category-form"},[_c('b-overlay',{attrs:{"show":_vm.loading,"no-wrap":"","spinner-variant":"primary"}}),_c('validation-observer',{ref:"observer",staticClass:"w-100"},[_c('b-row',[_c('b-col',{attrs:{"md":12}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Pinfl')}},[_c('b-input',{model:{value:(_vm.form.pinfl),callback:function ($$v) {_vm.$set(_vm.form, "pinfl", $$v)},expression:"form.pinfl"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Firstname')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Firstname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"md":6}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Lastname')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"md":6}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Middlename')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Middlename'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.middle_name),callback:function ($$v) {_vm.$set(_vm.form, "middle_name", $$v)},expression:"form.middle_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"md":6}},[_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Email')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.SchoolType')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.SchoolType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.schoolTypes,"text-field":"name","value-field":"id"},model:{value:(_vm.form.school_type),callback:function ($$v) {_vm.$set(_vm.form, "school_type", $$v)},expression:"form.school_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.BirthDate')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.BirthDate'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.birth_date),expression:"form.birth_date"}],staticClass:"form-control mb-2",attrs:{"type":"date"},domProps:{"value":(_vm.form.birth_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "birth_date", $event.target.value)}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.PhoneNumber')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.PhoneNumber'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:('+998 ## ### ## ##'),expression:"'+998 ## ### ## ##'"}],attrs:{"type":"text","name":"phone_number"},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.SecondNumber')}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:('+998 ## ### ## ##'),expression:"'+998 ## ### ## ##'"}],attrs:{"type":"text","name":"second_number"},model:{value:(_vm.form.second_number_second),callback:function ($$v) {_vm.$set(_vm.form, "second_number_second", $$v)},expression:"form.second_number_second"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Region')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Region'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.regions,"text-field":"nameuz","value-field":"id"},on:{"input":_vm.fetchRayons},model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Rayon')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Rayon'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.rayons,"text-field":"region","value-field":"id"},model:{value:(_vm.form.rayon),callback:function ($$v) {_vm.$set(_vm.form, "rayon", $$v)},expression:"form.rayon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Street')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Street'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"name":"street","type":"text"},model:{value:(_vm.form.street),callback:function ($$v) {_vm.$set(_vm.form, "street", $$v)},expression:"form.street"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.SchoolNumber')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.SchoolNumber'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"name":"street","type":"text"},model:{value:(_vm.form.school_number),callback:function ($$v) {_vm.$set(_vm.form, "school_number", $$v)},expression:"form.school_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.SchoolPhoneNumber')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.SchoolPhoneNumber'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:('+998 ## ### ## ##'),expression:"'+998 ## ### ## ##'"}],attrs:{"type":"text","name":"phone_number"},model:{value:(_vm.form.school_phone_number),callback:function ($$v) {_vm.$set(_vm.form, "school_phone_number", $$v)},expression:"form.school_phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Experience')}},[_c('validation-provider',{attrs:{"name":_vm.$t('message.Experience'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.experiences,"text-field":"name_ru","value-field":"id"},model:{value:(_vm.form.experience),callback:function ($$v) {_vm.$set(_vm.form, "experience", $$v)},expression:"form.experience"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.file')}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('message.photo')}},[_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},model:{value:(_vm.form.photo),callback:function ($$v) {_vm.$set(_vm.form, "photo", $$v)},expression:"form.photo"}})],1)],1),_c('b-col',{attrs:{"md":6}}),_c('b-col',{attrs:{"md":6}},[(!_vm.id)?_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Password')}},[_c('b-input',{attrs:{"type":"password","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1):_c('div',{staticClass:"w-100"},[_c('b-form-group',{attrs:{"label":_vm.$t('message.Password')}},[_c('b-input',{attrs:{"type":"password","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1)])],1),_c('b-divider')],1),_c('div',{staticClass:"w-100 mt-3 d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.saveSubmit}},[_vm._v(_vm._s(_vm.$t('message.Add')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }